import Layout from "components/Layouts/default"
import Spinner from "react-spinner-material"

const index = () => {
  return (
    <Layout>
      <div className="columns is-centered mt-6">
        <div className="column is-narrow">
          <Spinner radius={12} color={"black"} stroke={1} />
        </div>
      </div>
    </Layout>
  )
}

export default index
