import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.scss"

const Box = ({ renderHeader, children, renderFooter }) => {
  return (
    <div className={styles.wrapper}>
      {renderHeader && (
        <header className={styles.header}>{renderHeader()}</header>
      )}
      <div className={styles.content}>{children}</div>
      {renderFooter && (
        <footer className={styles.footer}>{renderFooter()}</footer>
      )}
    </div>
  )
}

Box.propTypes = {
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
}

export default Box
