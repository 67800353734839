import React from "react"
import styles from "./title.module.scss"

type TitleProps = {
  name: string
  children?: React.ReactNode
  annotation?: string
}

const Title: React.FC<TitleProps> = ({ children, name, annotation }) => {
  return (
    <div className="mb-40">
      <div className="level">
        <div className="level-left" style={{ width: "50%" }}>
          <h1 className="h1">
            {name}
            <span className="is-small" style={{ display: "block" }}>
              {annotation}
            </span>
          </h1>
        </div>

        {children && (
          <div className={`level-right ${styles.menu}`}>{children}</div>
        )}
      </div>
    </div>
  )
}

export default Title
