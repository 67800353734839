import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleCheck,
  faCircleInfo,
  faClock,
} from "@fortawesome/free-solid-svg-icons"
import Link from "next/link"

type StepProps = {
  title: string
  url?: string
  description: string
  status?: "complete" | "incomplete" | "warning"
}

const Step: React.FC<StepProps> = ({
  title,
  url,
  description,
  status = "incomplete",
}) => {
  const isExternalLink = url && url.startsWith("http")
  const AnchorComponent = () =>
    url ? (
      isExternalLink ? (
        <a target="_blank" rel="noreferrer" href={url}>
          {title}
        </a>
      ) : (
        <Link href={url}>{title}</Link>
      )
    ) : (
      title
    )

  let icon = faClock
  let color = "#ddd"

  if (status === "complete") {
    icon = faCircleCheck
    color = "#257953"
  } else if (status === "warning") {
    icon = faCircleInfo
    color = "grey"
  }

  return (
    <div className="is-flex is-align-items-center mt-4">
      <div className="icon has-text-left" style={{ flex: "0 0 24px" }}>
        <FontAwesomeIcon icon={icon} color={color} />
      </div>
      <div className="ml-3">
        <h5>
          <AnchorComponent />
        </h5>
        <p className="font-size-s has-text-grey">{description}</p>
      </div>
    </div>
  )
}

export default Step
