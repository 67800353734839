import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "components/Box"
import Layout from "components/Layouts/default"
import Title from "components/Title"
import { useUser } from "contexts/UserContext"
import {
  faCircle,
  faCircleCheck,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons"
import { gql } from "graphql/gql"
import { useQuery } from "@apollo/client"
import LoadingPage from "components/LoadingPage"
import ErrorPage from "components/ErrorPage"
import { CALENDLY_URL } from "../constants"
import Link from "next/link"
import Step from "../components/Step/Step"

const DESIGNER_ONBOARDING_QUERY = gql(`
  query DesignerOnboarding ($designerHandle: String!) {
    pieces(first: 1, designerHandle: $designerHandle) {
      edges {
        node {
          id
        }
      }
    }
    getDates(handle: $designerHandle) {
      id
    }
    pieceCollections(first: 1, brandHandle: $designerHandle) {
      edges {
        node {
          id
        }
      }
    }
  }
`)

export default function Home() {
  const { designerHandles, userFirstName, isAdminUser } = useUser()
  const title = userFirstName
    ? "Welcome to your workbench, " + userFirstName
    : "Welcome to your workbench"

  const firstDesignerHandle = designerHandles[0] || ""

  const { loading, data, error } = useQuery(DESIGNER_ONBOARDING_QUERY, {
    variables: { designerHandle: firstDesignerHandle },
    skip: !firstDesignerHandle,
  })

  // If this user does not have an associated designer handle, show the
  // regular welcome page.
  if (isAdminUser || !firstDesignerHandle) {
    return (
      <Layout>
        <div
          className={`columns is-vcentered is-centered is-columns-vcentered`}
        >
          <div className={`column is-7 has-text-centered`}>
            <p className="callout">
              <span>Jewellery</span> from the world&apos;s <span>finest</span>{" "}
              designers
            </p>
          </div>
        </div>
      </Layout>
    )
  }

  if (loading || !data) {
    return <LoadingPage />
  } else if (error) {
    return <ErrorPage error={error} />
  }

  const hasPieces = data.pieces.edges.length > 0
  const hasVacationDates = data.getDates.length > 0
  const hasPieceCollections = data.pieceCollections.edges.length > 0

  return (
    <Layout>
      <Title name={title} />

      <div className="columns">
        <div className="column is-one-third-fullhd is-half-widescreen is-two-thirds-desktop">
          <Box
            renderHeader={() => <h4 className="mb-0">Setting your account</h4>}
          >
            <p>
              Your workbench will allow you to set up your profile, create new
              pieces and collections, manage your orders, and check your
              payouts.
            </p>
            <p>
              Use the following checklist to learn how to navigate these
              different sections.
            </p>
            <hr />
            <div className="is-flex is-align-items-center">
              <div className="icon has-text-left" style={{ flex: "0 0 24px" }}>
                <FontAwesomeIcon icon={faCircleCheck} color="#257953" />
              </div>
              <div className="ml-3">
                <h5 className="has-text-success-dark">Register</h5>
                <p className="font-size-s has-text-grey">
                  Complete your self-registration successfully.
                </p>
              </div>
            </div>

            <Step
              title="Add your first piece"
              url={`/designers/${firstDesignerHandle}/pieces`}
              status={hasPieces ? "complete" : "incomplete"}
              description="You can add as many pieces as you want. Complete them with
                    images, prices, descriptions, and more."
            />

            <Step
              title="Add your first collection"
              url={`/designers/${firstDesignerHandle}/collections`}
              status={hasPieceCollections ? "complete" : "incomplete"}
              description="Collections are useful to organise your pieces and for your
              customers to navigate your work."
            />

            <Step
              title="Complete your marketing tasks"
              url={`/marketing`}
              status="warning"
              description="Promote your brand and relationship with Finematter using our customised marketing assets"
            />

            <Step
              title="Add vacation days"
              url={`/designers/${firstDesignerHandle}/vacations`}
              status={hasVacationDates ? "complete" : "incomplete"}
              description="It is important that we know whether you will be taking some
              (well-deserved) time off, so we can plan around and provide
              your customers with accurate delivery dates."
            />

            <Step
              title="Book a photoshoot"
              url={CALENDLY_URL}
              status="warning"
              description="Remember to book a photoshoot for your pieces, be it in London or Porto."
            />
          </Box>
        </div>
      </div>
    </Layout>
  )
}
